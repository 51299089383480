.page-footer {
  background: url("/images/wooden-wall@2x.png") repeat-x;
  background-size: 568px 380px;
  color: #aea196;
  padding: 30px 0 5px;
  a {
    color: #d2c1b3;
    text-decoration: none;
  }
  ul {
    @extend %plain-list;
    display: flex;
    gap: 30px;
  }
  .nav {
    margin-bottom: 10px;
    flex-wrap: wrap;
    justify-content: center;
    gap: 5px 30px;
  }
  .message {
    color: #ddd;
    margin-bottom: 15px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5px 0;
    .long {
      grid-column: span 2;
    }
  }
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .shop-contact {
    margin: 12px 0;
  }
  .copyright {
    color: #bbb;
  }
}

@media (max-width: 579px) {
  .page-footer {
    .shop-contact {
      flex-direction: column;
      gap: 5px;
    }
  }
}

@media (max-width: 549px) {
  .page-footer {
    .message {
      gap: 5px;
      grid-template-columns: 1fr;
      .long {
        grid-column: span 1;
      }
    }
  }
}

@media (max-width: 379px) {
  .page-footer {
    padding-bottom: 0;
  }
}