html {
  font-family: 'Noto Sans JP', sans-serif;
  //background-color: var(--color-bg);
  //color: var(--color-text);
  line-height: 1.5;
  scroll-behavior: smooth;
}

body {
  background-image: url("/images/wall@2x.png");
  background-size: 200px 200px;
}

img {
  max-width: 100%;
}

p {
  margin-top: 0;
}

em {
    color: var(--color-brown-light);
    font-style: normal;
}

a {
  text-decoration: none;
  color: var(--color-green);
}

select, input, textarea {
  border: #ccc 1px solid;
  border-radius: var(--border-radius);
  padding: 4px;
  line-height: inherit;
  max-width: 100%;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}