:root {
  --color-wood-light: #aea196;
  --color-green: #76a048;
  --color-brown: #604926;
  --color-brown-light: #b1966e;
  --color-brown-pale: #e7ded1;
  --color-blue: #59a0b7;
  --border-radius: 4px;
}

%visually-hide {
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap; 
  width: 1px;
}

%plain-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
