.page-top-info {
  background-image: url("/images/wooden-bar@2x.png");
  background-size: 900px 60px;
  font-size: 15px;
  border-bottom: #111 1px solid;
  box-shadow: 0 0 2px 1px #222;
  ul {
    @extend %plain-list;
    color: #eee;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  li {
    display: block;
    padding: 4px 0;
  }
  a {
    color: #eee;
    text-decoration: none;
  }
  i.fa {
    color: var(--color-wood-light);
    margin-right: 4px;
  }
}

.top-nav {
  background-color: #fff;
  padding: 8px 0;
  border-bottom: #ddd 1px solid;
  .container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  h1.logo {
    margin: 0;
    a, svg {
      display: block;
    }
  }
  nav, nav ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  nav ul {
    gap: 20px;
  }
  nav a {
    display: block;
    color: var(--color-brown);
    text-decoration: none;
    padding: 20px 15px;
  }
  nav li:last-child a {
    padding-right: 0;
  }
  ul {
    @extend %plain-list;
  }
  .menu-toggle {
    display: none;
  }
  .restaurant {
    > a {
      display: flex;
      flex-direction: column;
      padding: 0 15px;
    }
    svg {
      align-self: flex-end;
    }
    .title {
      font-size: 12px;
      margin-right: 20px;
    }
  }
}

@media (max-width: 1023px) {
  .top-nav {
    nav ul {
      gap: 10px;
    }
  }
}

@media (max-width: 960px) {
  .page-top-info li.shop {
    display: none;
  }
  .top-nav {
    h1 svg {
      width: 150px;
      height: auto !important;
    }
    nav a {
      padding: 15px 5px;
    }
  }
}

@media (max-width: 767px) {
  .page-top-info li.fb {
    display: none;
  }
  .top-nav {
    .container {
      flex-direction: column;
      align-items: center;
    }
    h1.logo {
      margin-top: 10px;
    }
  }
}

@media (max-width: 650px) {
  .page-top-info {
    ul {
      justify-content: flex-start;
      gap: 30px;
    }
    li.contact {
      display: none;
    }
  }
}

@media (max-width: 619px) {
  .top-nav {
    padding-bottom: 0;
    .container {
      width: 100%;
    }
    h1.logo {
      margin-bottom: 15px;
    }
    .menu-toggle {
      display: block;
      border: none;
      width: 100%;
      border-radius: 0;
      border-top: #eee 1px solid;
      padding: 12px 0;
      color: var(--color-brown);
      font-size: 1.1em;
      line-height: 1em;
      background-color: transparent;
      .fa {
        transition: transform .2s ease-in;
      }
    }
    .menu-toggle.active {
      background: #f5f5f5;
      & + ul {
        max-height: 350px;
      }
      .fa {
        transform: rotate(-180deg);
      }
    }
    nav, nav ul {
      width: 100%;
      flex-direction: column;
      gap: 0;
      align-items: stretch;
    }
    nav ul li {
      border-top: #eee 1px solid;
      text-align: center;
      font-size: 1.1em;
    }
    nav ul {
      max-height: 0;
      overflow: hidden;
      transition: max-height .2s ease-in;
    }
    .restaurant {
      a {
        padding: 5px;
      }
      .title {
        margin-right: 0;
      }
      svg {
        margin: 0 auto;
      }
    }
  }
}

@media (max-width: 530px) {
  .page-top-info {
    ul {
      flex-direction: column;
      gap: 0;
      align-items: flex-start;
      padding: 4px 0;
    }
    li {
      display: flex;
      padding: 0;
      i.fa {
        position: relative;
        top: 4px;
      }
      span > span {
        display: inline-block;
      }
    }
  }

}