.fa {
  margin-right: 5px;
}

.visually-hide {
  @extend %visually-hide;
}

.required {
  color: #c55d49;
  margin-right: 4px;
}

.page-title {
  color: var(--color-brown);
  margin-top: 0;
  margin-bottom: 25px;
  .fa {
    color: var(--color-green);
    margin-right: 4px;
  }
}

.shop-info + .page-title {
  margin-top: 30px;
}

.page-subtitle {
  color: var(--color-brown-light);
  font-size: 1.3em;
  margin-top: 0;
  margin-bottom: 15px;
}

.full-img {
  max-width: 100%;
  border: #ddd 1px solid;
  padding: 6px;
  background: #fff;
  margin-top: 20px;
  margin-bottom: 30px;
  box-shadow: 0 0 5px 0 rgb(0 0 0 / 5%);
  &.map {
    max-width: 720px;
    margin: 20px auto 30px;
    iframe {
      display: block;
      width: 100%;
      height: 400px;
    }
  }
  img {
    display: block;
  }
}

h4.has-icon {
  color: var(--color-brown);
  margin-top: 0;
  margin-bottom: 10px;
  display: flex;
  .fa {
    margin-right: 5px;
    color: var(--color-blue);
    position: relative;
    top: 5px;
  }
}

.sp-content-block {
  background: rgba(207, 200, 193, 0.5);
  border-radius: 5px;
  padding: 30px;
  margin-bottom: 30px;
  line-height: 1.7;
  h3 {
    text-align: center;
    font-size: 1.3em;
    color: var(--color-brown-light);
    margin-top: 0;
    margin-bottom: 15px;
  }
  *:last-child {
    margin-bottom: 0;
  }
}

.control-group {
  display: flex;
  gap: 2%;
  justify-content: center;
  margin-bottom: 10px;
  .control-label {
    width: 25%;
    text-align: right;
  }
  .controls {
    width: 73%;
    .full {
      width: 500px;
    }
    .short {
      width: 50px;
    }
    textarea {
      height: 200px;
    }
  }
}

.actions {
  text-align: center;
  margin: 30px 0;
  .btn {
    display: inline-block;
    font-size: 1.3em;
    background: #76a048;
    color: #fff;
    padding: 15px 30px;
    margin: 0 auto;
    border: #ddd 1px solid;
    border-radius: 30px;
    &:disabled {
      opacity: 0.8;
    }
  }
}

@media (max-width: 639px) {
  .control-group {
    flex-direction: column;
    .control-label {
      width: 100%;
      text-align: left;
    }
    .controls {
      width: 100%;
      .full {
        width: 100%;
      }
      input:not(.short) {
        width: 100%;
      }
    }
  }
}

@media (max-width: 480px) {
  .full-img.map iframe {
    height: 300px;
  }
}