* {
  box-sizing: border-box;
}

html, body {
  height: 100%;
}

#root {
  height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
}

.page-footer {
  grid-row-start: 2;
  grid-row-end: 3;
}

.container {
  margin: 0 auto;
  width: clamp(300px, 90vw, 980px);
}

.main-wrap {
  padding: 25px 0 30px;
  line-height: 1.6em;
  color: #505050;
}

.content-block {
  padding: 25px 0;
  line-height: 1.7;
  border-top: #f5f5f5 1px solid;
  box-shadow: 0 -1px 0 0 #e2dedb;
  &:first-child {
    border-top: none;
    box-shadow: none;
    padding-top: 0;
  }
  .page-subtitle {
    margin-bottom: 20px;
  }
  &__content {
    display: flex;
    gap: 45px;
    justify-content: space-between;
  }
  &__content + &__content {
    margin-top: 15px;
  }
  &__text {
    flex-grow: 1;
    *:first-child {
      margin-top: 0;
    }
  }
  &__img {
    flex-basis: 33%;
    flex-shrink: 0;
    border: #ddd 1px solid;
    padding: 6px;
    background: #fff;
    max-width: 332px;
    box-shadow: 0 0 5px 0 rgb(0 0 0 / 5%);
    box-sizing: border-box;
    align-self: flex-start;
    img {
      display: block;
    }
  }
  &__subblock {
    margin-bottom: 30px;
  }
  .more {
    display: flex;
    position: relative;
    align-items: center;
    float: right;
    background: #fff;
    background: rgba(255, 255, 255, 0.8);
    padding: 5px 15px;
    margin: 20px 0;
    border: #ddd 1px solid;
    border-radius: 20px;
    color: var(--color-brown);
    transition: color .1s ease-in, box-shadow .1s ease-in;
    &:hover {
      color: #76a048;
      box-shadow: 0 0 3px 0 rgb(169 200 134 / 80%);
    }
    .fa {
      color: #a9c886;
    }
  }
}

.content-block-wrapper {
  &--ileft {
    .content-block__content {
      flex-direction: row-reverse;
    }
  }
  &--iswitch {
    .content-block:nth-child(even) {
      .content-block__content {
        flex-direction: row-reverse;
      }
    }
  }
}

.normal-block {
  margin-bottom: 30px;
}

.wide-only {
  display: block;
}

.mobi-only {
  display: none;
}

@media (max-width: 767px) {
  .wide-only {
    display: none;
  }
  .mobi-only {
    display: block;
  }
}

@media (max-width: 639px) {
  .content-block, .content-block-wrapper--iswitch .content-block:nth-child(even) {
    .content-block__content {
      flex-direction: column;
      gap: 0;
    }
    .content-block__img {
      max-width: none;
    }
    .more {
      margin-top: 0;
    }
  }
}