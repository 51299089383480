@import "normalize";
@import "variables-and-functions";

@import "base";
@import "utility";
@import "layout";

@import "page-header";
@import "page-footer";

.message {
  li {
    display: flex;
  }
  .fa {
    position: relative;
    top: 5px;
  }
}

.shop-info {
  background: rgba(255, 255, 255, 0.8);
  margin-bottom: 5px;
  padding: 5px 20px 5px 10px;
  position: relative;
  border-radius: 4px;
  border: #fff 1px solid;
  color: #555;
  &--important {
    background: #9d342f;
    color: #eee;
    a {
      color: #fff;
      text-decoration: underline;
    }
  }
  ul:not(.ul) {
    @extend %plain-list;
  }
  ul.ul {
    margin: 0;
    padding-left: 25px;
  }
  .fa {
    color: var(--color-blue);
    margin-right: 5px;
  }
}

.info {
  @extend %plain-list;
  li {
    display: flex;
    align-items: flex-start;
    margin-bottom: 5px;
  }
  li > .fa {
    color: var(--color-blue);
    margin-right: 5px;
    position: relative;
    top: 5px;
  }
  li > h4 {
    margin: 0;
  }
}

.purchase-info, .contact-info {
  background: rgba(207, 200, 193, 0.5);
  border-radius: 5px;
  padding: 30px;
  margin-bottom: 30px;
  h3:first-child {
    margin-top: 0;
  }
}

.selected-info {
  margin-bottom: 30px;
  table {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
  }
  td {
    padding: 5px;
  }
  .right {
    text-align: right;
  }
  tfoot td {
    border-top: #aea196 1px solid;
    color: #76a048;
  }
}

.item-list {
  @extend %plain-list;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
  li {
    background: rgba(255, 255, 255, 0.6);
    padding: 10px;
    border-radius: var(--border-radius);
  }
  &__item-name {
    font-weight: bold;
  }
  &__item-info {
    color: var(--color-wood-light);
  }
  &__item-quantity {
    margin-top: 5px;
    padding-top: 5px;
    border-top: var(--color-brown-pale) 1px solid;
    display: flex;
    justify-content: flex-end;
    input {
      width: 80px;
    }
  }
}

.highlight-block {
  background: rgba(207, 200, 193, 0.5);
  border-radius: 5px;
  padding: 30px;
  margin-bottom: 30px;
  .page-subtitle {
    text-align: center;
  }
  footer {
    text-align: right;
  }
}

.shipping-info {
  background: var(--color-brown);
  color: #fff;
  border-radius: var(--border-radius);
  padding: 15px;
  margin-bottom: 30px;
  h3 {
    color: var(--color-brown-pale);
    margin-top: 0;
  }
  h4 {
    color: #ddd;
    margin-top: 0;
    margin-bottom: 5px;
  }
  table {
    width: 100%;
    max-width: none;
    color: #ddd;
    margin-bottom: 20px;
    thead th {
      border-bottom: #806d51 1px solid;
    }
    th, td {
      text-align: center;
      padding: 3px 5px;
    }
  }
}

.user-info {
  .control-label {
    line-height: 36px;
  }
}

.no-shipping-list {
  background: var(--color-wood-light);
  color: #fff;
  display: block;
  width: 230px;
  margin: 0 auto;
  padding: 5px 15px;
  border-radius: 20px;
  text-align: center;
}

.loading {
  display: block;
  background: url("/images/loading.svg") no-repeat;
  width: 24px;
  height: 24px;
  opacity: 0.8;
  span {
    display: none;
  }
}

.mail-sent {
  background: var(--color-brown-pale);
  border-radius: var(--border-radius);
  padding: 30px;
  margin-bottom: 30px;
  &.error {
    background: #a00;
    color: #eee;
    a {
      color: #fff;
      text-decoration: underline;
    }
  }
}

.modal-open {
  overflow: hidden;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(#000, 0.7);
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  &_block {
    background: #fff;
    width: clamp(300px, 90vw, 680px);
    padding: 30px;
    position: relative;
    h2 {
      margin-top: 0;
      color: var(--color-brown);
      text-align: center;
    }
    footer {
      text-align: right;
      color: var(--color-green);
    }
  }
  &_close {
    position: absolute;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 36px;
    border-radius: 0;
    background: #ddd;
    border: none;
    line-height: 1em;
    top: -60px;
    right: 0;
  }
}



@media (max-width: 960px) {
  .item-list {
    gap: 15px;
  }
}

@media (max-width: 819px) {
  .item-list {
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
}
@media (max-width: 640px) {
  .purchase-info, .contact-info {
    padding: 15px;
  }
}

@media (max-width: 530px) {
  .item-list {
    grid-template-columns: 1fr;
    gap: 10px;
  }
}